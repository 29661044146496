import PropTypes from "prop-types";
import { memo } from "react";
import Paragraphs from "app/pages/.shared/Paragraphs";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import { ProductsCarousel } from "app/pages/.shared/ProductsCarousel";
import { FormattedMessage } from "react-intl";
import RelativeLink from "app/pages/.shared/RelativeLink";
import IconRight from "app/pages/.shared/static/icons/IconRight";
import Product from "@brand/Product/default/Product";
import ProductList from "app/pages/Listing/ProductList/ProductList";
import "./ProductListBlock.scss";

const ProductListBlock = ({
	title,
	shortVersion,
	shouldHideProductPriceBanner,
	products = [],
	showSmartDPOnRootUrl,
	redirectUrl,
	isMobile,
}) => {
	if (products.length === 0) {
		return false;
	}

	let productsNode = false;

	if (isMobile) {
		if (shortVersion) {
			productsNode = (
				<ProductsCarousel
					products={products}
					hidePriceBanner={shouldHideProductPriceBanner}
					showSmartDPOnRootUrl={showSmartDPOnRootUrl}
					noSwiping={!isMobile}
				/>
			);
		} else {
			productsNode = (
				<ProductList
					products={products}
					hidePriceBanner={shouldHideProductPriceBanner}
					hideMembershipBlock={true}
					showSmartDPOnRootUrl={showSmartDPOnRootUrl}
				/>
			);
		}
	} else if (shortVersion) {
		productsNode = (
			<div className="product-list-block__products">
				{products.map((product, index) => {
					return (
						<div key={product.id} data-testid={`product-${product.uri}`}>
							<Product
								product={product}
								position={index}
								hidePriceBanner={shouldHideProductPriceBanner}
							/>
						</div>
					);
				})}
			</div>
		);
	} else {
		productsNode = (
			<ProductList
				products={products}
				hidePriceBanner={shouldHideProductPriceBanner}
				hideMembershipBlock={true}
				showSmartDPOnRootUrl={showSmartDPOnRootUrl}
			/>
		);
	}

	return (
		<div className="product-list-block">
			<div className="product-list-block__title">
				{title && (
					<Typography
						variant={TYPOGRAPHY_VARIANTS.XL3}
						variantMD={TYPOGRAPHY_VARIANTS.XL4}
						isBold
						component="h2"
					>
						<Paragraphs paragraphs={title} enableHTMLFormatting />
					</Typography>
				)}

				{!isMobile && shortVersion && (
					<div className="product-list-block__view-all-offers-link">
						<RelativeLink variant="naked" to={redirectUrl}>
							<Typography variant={TYPOGRAPHY_VARIANTS.LARGE} isBold>
								<FormattedMessage id="products.carousel.view.all.offers.label" />
							</Typography>
							<IconRight width={16} height={16} />
						</RelativeLink>
					</div>
				)}
			</div>

			{productsNode}

			{isMobile && shortVersion && (
				<div className="product-list-block__view-all-offers-button">
					<RelativeLink variant="secondary" to={redirectUrl}>
						<Typography variant={TYPOGRAPHY_VARIANTS.LARGE} isBold>
							<FormattedMessage id="products.carousel.view.all.offers.label" />
						</Typography>
						<IconRight width={16} height={16} />
					</RelativeLink>
				</div>
			)}
		</div>
	);
};

ProductListBlock.propTypes = {
	products: PropTypes.array,
	showSmartDPOnRootUrl: PropTypes.bool,
	title: PropTypes.string,
	redirectUrl: PropTypes.string,
	shouldHideProductPriceBanner: PropTypes.bool,
	shortVersion: PropTypes.bool,
	isMobile: PropTypes.bool,
};

export default memo(ProductListBlock);
